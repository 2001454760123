import { defineAction } from '_utils/redux'
import * as budgetServices from '_services/budget'

export const GET_BUDGET = defineAction('GET_BUDGET')
export const GET_BUDGET_BY_ID = defineAction('GET_BUDGET_BY_ID')
export const DELETE_BUDGET = defineAction('DELETE_BUDGET')
export const GET_BUDGET_BY_SERVICE_ORDER = defineAction('GET_BUDGET_BY_SERVICE_ORDER')
export const GET_BUDGET_FOR_PROVIDER = defineAction('GET_BUDGET_FOR_PROVIDER')
export const GET_PAYER_OPTIONS = defineAction('GET_PAYER_OPTIONS')
export const UPDATE_BUDGET = defineAction('UPDATE_BUDGET')
export const UPDATE_BUDGET_ITEMS = defineAction('UPDATE_BUDGET_ITEMS')
export const DELETE_BUDGET_ITEM = defineAction('DELETE_BUDGET_ITEM')
export const UPDATE_BUDGET_ITEM = defineAction('UPDATE_BUDGET_ITEM')
export const DELETE_BUDGET_PICTURE = defineAction('DELETE_BUDGET_PICTURE')
export const DELETE_BUDGET_PICTURES = defineAction('DELETE_BUDGET_PICTURES')
export const ADD_BUDGET_PICTURE = defineAction('ADD_BUDGET_PICTURE')
export const IMPORT_ATTACHMENTS = defineAction('IMPORT_ATTACHMENTS')
export const REFUSE_BUDGET = defineAction('REFUSE_BUDGET')
export const CLIENT_REFUSE_BUDGET = defineAction('CLIENT_REFUSE_BUDGET')
export const UNREFUSE_BUDGET = defineAction('UNREFUSE_BUDGET')
export const SENT_BUDGET_COUNTER_PROPOSAL = defineAction('SENT_BUDGET_COUNTER_PROPOSAL')
export const APPROVE_PICTURES_BUDGET = defineAction('APPROVE_PICTURES_BUDGET')
export const APPROVE_BUDGET = defineAction('APPROVE_BUDGET')
export const CLIENT_APPROVE_BUDGET = defineAction('CLIENT_APPROVE_BUDGET')
export const CANCEL_APPROVE_BUDGET = defineAction('CANCEL_APPROVE_BUDGET')
export const SEND_BUDGET_EMAIL = defineAction('SEND_BUDGET_EMAIL')
export const SEND_BUDGET_WHATSAPP = defineAction('SEND_BUDGET_WHATSAPP')
export const EXTERNAL_APPROVE_CUSTOMER_BUDGET = defineAction('EXTERNAL_APPROVE_CUSTOMER_BUDGET')
export const EXTERNAL_REFUSE_CUSTOMER_BUDGET = defineAction('EXTERNAL_REFUSE_CUSTOMER_BUDGET')
export const CREATE_PROPOSE = defineAction('CREATE_PROPOSE')
export const CREATE_OPEN_PROPOSE_BUDGET = defineAction('CREATE_OPEN_PROPOSE_BUDGET')
export const RETURN_BUDGET_PROVIDER = defineAction('RETURN_BUDGET_PROVIDER')
export const SEND_WHATSAPP = defineAction('SEND_WHATSAPP')
export const SEND_REASON_WHATSAPP = defineAction('SEND_REASON_WHATSAPP')
export const GET_STEP_STATUS_LOG = defineAction('GET_STEP_STATUS_LOG')
export const GET_EDIT_EXECUTION_INFO = defineAction('GET_EDIT_EXECUTION_INFO')
export const GET_VERIFY_PAID_INSTALLMENT = defineAction('GET_VERIFY_PAID_INSTALLMENT')
export const FETCH_PAID_INSTALLMENTS = defineAction('FETCH_PAID_INSTALLMENTS')
export const FETCH_BUDGET_SERVICES = defineAction('FETCH_BUDGET_SERVICES')
export const POST_BUDGET_SERVICE = defineAction('POST_BUDGET_SERVICE')
export const PATCH_BUDGET_SERVICE = defineAction('PATCH_BUDGET_SERVICE')
export const DELETE_BUDGET_SERVICE = defineAction('DELETE_BUDGET_SERVICE')
export const GET_REASONS_TO_REFUSAL = defineAction('GET_REASONS_TO_REFUSAL')
export const BUDGET_IMPORT_SUGGESTIONS = defineAction('BUDGET_IMPORT_SUGGESTIONS')
export const BUDGET_UNDO_IMPORT_SUGGESTIONS = defineAction('BUDGET_UNDO_IMPORT_SUGGESTIONS')
export const POST_BUDGET_SAVE_TEMPLATES = defineAction('POST_BUDGET_SAVE_TEMPLATES')
export const PATCH_BUDGET_SAVE_TEMPLATES = defineAction('POST_BUDGET_SAVE_TEMPLATES')
export const DELETE_BUDGET_SAVE_TEMPLATES = defineAction('DELETE_BUDGET_SAVE_TEMPLATES')
export const GET_BUDGET_SAVE_TEMPLATES = defineAction('GET_BUDGET_SAVE_TEMPLATES')
export const GET_BUDGET_LOAD_TEMPLATES = defineAction('GET_BUDGET_LOAD_TEMPLATES')
export const POST_BUDGET_LOAD_TEMPLATES = defineAction('POST_BUDGET_LOAD_TEMPLATES')
export const VERIFY_BUDGET_FINANCIAL_VALUES = defineAction('VERIFY_BUDGET_FINANCIAL_VALUES')
export const GET_BUDGET_STATUS = defineAction('GET_BUDGET_STATUS')

export const CREATE_BUDGET_TEMPLATE = defineAction('CREATE_BUDGET_TEMPLATE')

export const CREATE_COVERAGE_RULE = defineAction('CREATE_COVERAGE_RULE')
export const GET_COVERAGE_RULE_VERSION = defineAction('GET_COVERAGE_RULE_VERSION')
export const UPDATE_BUDGET_COVERAGE = defineAction('UPDATE_BUDGET_COVERAGE')

export const getBudget = (serviceOrderId, budgetId) => async (dispatch, getState) => {
  const data = await budgetServices.getBudget(getState().user.authToken, serviceOrderId, budgetId)

  dispatch({
    type: GET_BUDGET.ACTION,
    payload: async () => data,
  })
  return data
}

export const getBudgetById = budgetId => async (dispatch, getState) => {
  const data = await budgetServices.getBudgetById(getState().user.authToken, budgetId)

  dispatch({
    type: GET_BUDGET_BY_ID.ACTION,
    payload: async () => data,
  })

  return data
}

export const getPayerOptions = (serviceOrderId, budgetId) => async (dispatch, getState) => {
  const data = await budgetServices.getPayerOptions(
    getState().user.authToken,
    serviceOrderId,
    budgetId
  )

  dispatch({
    type: GET_PAYER_OPTIONS.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateBudget =
  (serviceOrderId, budgetId, payload, options) => async (dispatch, getState) => {
    const data = await budgetServices.updateBudget(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload,
      options
    )
    dispatch({
      type: UPDATE_BUDGET.ACTION,
      payload: async () => data,
    })
    return data
  }

export const updateBudgetItems =
  (serviceOrderId, budgetId, payload, options) => async (dispatch, getState) => {
    const data = await budgetServices.updateBudget(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload,
      options
    )
    dispatch({
      type: UPDATE_BUDGET_ITEMS.ACTION,
      payload: async () => data,
    })
    return data
  }

export const getBudgetByServiceOrder = serviceOrderId => async (dispatch, getState) => {
  const data = await budgetServices.getBudgetByServiceOrder(
    getState().user.authToken,
    serviceOrderId
  )

  dispatch({
    type: GET_BUDGET_BY_SERVICE_ORDER.ACTION,
    payload: async () => data,
  })

  return data
}

export const createOpenProposeBudget = serviceOrderId => async (dispatch, getState) => {
  const data = await budgetServices.createOpenProposeBudget(
    getState().user.authToken,
    serviceOrderId
  )

  dispatch({
    type: CREATE_OPEN_PROPOSE_BUDGET.ACTION,
    payload: async () => data,
  })

  return data
}

export const deleteBudgetItem = (serviceOrderId, budgetId, itemId) => (dispatch, getState) => {
  dispatch({
    type: DELETE_BUDGET_ITEM.ACTION,
    payload: budgetServices.deleteBudgetItem(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      itemId
    ),
    meta: {
      itemId,
      budgetId,
    },
  })
}
export const deleteBudget = (serviceOrderId, budgetId) => (dispatch, getState) =>
  dispatch({
    type: DELETE_BUDGET.ACTION,
    payload: budgetServices.deleteBudget(getState().user.authToken, serviceOrderId, budgetId),
    meta: {
      serviceOrderId,
      budgetId,
    },
  })

export const updateBudgetItem =
  (serviceOrderId, budgetId, itemId, payload) => (dispatch, getState) => {
    dispatch({
      type: UPDATE_BUDGET_ITEM.ACTION,
      payload: budgetServices.updateBudgetItem(
        getState().user.authToken,
        serviceOrderId,
        budgetId,
        itemId,
        payload
      ),
      meta: {
        itemId,
        budgetId,
      },
    })
  }

export const deleteBudgetPicture =
  (serviceOrderId, budgetId, pictureId) => (dispatch, getState) => {
    dispatch({
      type: DELETE_BUDGET_PICTURE.ACTION,
      payload: budgetServices.deleteBudgetPicture(
        getState().user.authToken,
        serviceOrderId,
        budgetId,
        pictureId
      ),
      meta: {
        budgetId,
        pictureId,
      },
    })
  }

export const deleteBudgetPictures =
  (serviceOrderId, budgetId, payload) => async (dispatch, getState) => {
    const data = await budgetServices.deleteBudgetPictures(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload
    )

    dispatch({
      type: DELETE_BUDGET_PICTURES.ACTION,
      payload: async () => data,
      meta: {
        budgetId,
      },
    })

    return data
  }

export const addBudgetPicture = (serviceOrderId, budgetId, payload) => (dispatch, getState) => {
  dispatch({
    type: ADD_BUDGET_PICTURE.ACTION,
    payload: budgetServices.addBudgetPicture(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload
    ),
    meta: {
      budgetId,
    },
  })
}

export const importAttachments = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.importAttachments(getState().user.authToken, serviceOrderId, budgetId)

  dispatch({
    type: IMPORT_ATTACHMENTS.ACTION,
    payload: data,
  })

  return data
}

export const refuseBudget =
  ({ serviceOrderId, budgetId, payload }) =>
  (dispatch, getState) => {
    const data = budgetServices.refuseBudget(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload
    )
    dispatch({
      type: REFUSE_BUDGET.ACTION,
      payload: data,
      meta: { serviceOrderId, payload },
    })
    return data
  }

export const clientRefuseBudget =
  ({ serviceOrderId, budgetId }) =>
  (dispatch, getState) => {
    dispatch({
      type: CLIENT_REFUSE_BUDGET.ACTION,
      payload: budgetServices.clientRefuseBudget(
        getState().user.authToken,
        serviceOrderId,
        budgetId
      ),
      meta: { serviceOrderId },
    })
  }

export const clientApproveBudget =
  (serviceOrderId, budgetId, payload) => async (dispatch, getState) => {
    const data = await budgetServices.clientApproveBudget(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload
    )
    dispatch({
      type: CLIENT_APPROVE_BUDGET.ACTION,
      payload: async () => data,
      meta: { serviceOrderId },
    })

    return data
  }

export const unrefuseBudget =
  ({ serviceOrderId, budgetId }) =>
  (dispatch, getState) => {
    dispatch({
      type: UNREFUSE_BUDGET.ACTION,
      payload: budgetServices.unrefuseBudget(getState().user.authToken, serviceOrderId, budgetId),
    })
  }

export const sendBudgetCounterProposal =
  ({ serviceOrderId, budgetId, payload }) =>
  (dispatch, getState) => {
    dispatch({
      type: SENT_BUDGET_COUNTER_PROPOSAL.ACTION,
      payload: budgetServices.sendBudgetCounterProposal(
        getState().user.authToken,
        serviceOrderId,
        budgetId,
        payload
      ),
    })
  }

export const approvePicturesBudget =
  (serviceOrderId, budgetId, payload) => (dispatch, getState) => {
    dispatch({
      type: APPROVE_PICTURES_BUDGET.ACTION,
      payload: budgetServices.approvePicturesBudget(
        getState().user.authToken,
        serviceOrderId,
        budgetId,
        payload
      ),
    })
  }

export const approveBudget = (serviceOrderId, budgetId, payload) => async (dispatch, getState) => {
  const data = await budgetServices.approveBudget(
    getState().user.authToken,
    serviceOrderId,
    budgetId,
    payload
  )

  dispatch({
    type: APPROVE_BUDGET.ACTION,
    payload: async () => data,
  })

  return data
}

export const cancelApproveBudget = (serviceOrderId, budgetId) => (dispatch, getState) => {
  dispatch({
    type: CANCEL_APPROVE_BUDGET.ACTION,
    payload: budgetServices.cancelApproveBudget(
      getState().user.authToken,
      serviceOrderId,
      budgetId
    ),
  })
}

export const sendBudgetEmail = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.sendBudgetEmail(getState().user.authToken, serviceOrderId, budgetId)
  dispatch({
    type: SEND_BUDGET_EMAIL.ACTION,
    payload: data,
  })
  return data
}

export const sendBudgetWhatsapp = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.sendBudgetWhatsapp(
    getState().user.authToken,
    serviceOrderId,
    budgetId
  )
  dispatch({
    type: SEND_BUDGET_WHATSAPP.ACTION,
    payload: data,
  })
  return data
}

export const approveCustomerBudget =
  (serviceOrderId, budgetId, payload) => (dispatch, getState) => {
    dispatch({
      type: EXTERNAL_APPROVE_CUSTOMER_BUDGET.ACTION,
      payload: budgetServices.approveCustomerBudget(
        getState().user.authToken,
        serviceOrderId,
        budgetId,
        payload
      ),
    })
  }

export const refuseCustomerBudget = (serviceOrderId, budgetId, payload) => (dispatch, getState) => {
  dispatch({
    type: EXTERNAL_REFUSE_CUSTOMER_BUDGET.ACTION,
    payload: budgetServices.refuseCustomerBudget(
      getState().user.authToken,
      serviceOrderId,
      budgetId,
      payload
    ),
  })
}

export const createPropose = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.createPropose(getState().user.authToken, serviceOrderId, budgetId)
  dispatch({
    type: CREATE_PROPOSE.ACTION,
    payload: data,
  })
  return data
}

export const returnBudgetProvider = (serviceOrderId, budgetId, payload) => (dispatch, getState) => {
  const data = budgetServices.returnBudgetProvider(
    getState().user.authToken,
    serviceOrderId,
    budgetId,
    payload
  )
  dispatch({
    type: RETURN_BUDGET_PROVIDER.ACTION,
    payload: data,
  })
  return data
}

export const sendWhatsapp = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.sendWhatsapp(getState().user.authToken, serviceOrderId, budgetId)
  dispatch({
    type: SEND_WHATSAPP.ACTION,
    payload: data,
  })
  return data
}

export const getStepStatusLog = (serviceOrderId, budgetId) => async (dispatch, getState) => {
  const data = await budgetServices.getStepStatusLog(
    getState().user.authToken,
    serviceOrderId,
    budgetId
  )

  dispatch({
    type: GET_STEP_STATUS_LOG.ACTION,
    payload: async () => data,
  })

  return data
}

export const verifyPaidInstallment = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.verifyPaidInstallment(
    getState().user.authToken,
    serviceOrderId,
    budgetId
  )

  dispatch({
    type: GET_VERIFY_PAID_INSTALLMENT.ACTION,
    payload: data,
  })
  return data
}

export const getEditExecutionInfo = (serviceOrderId, budgetId) => (dispatch, getState) => {
  const data = budgetServices.getEditExecutionInfo(
    getState().user.authToken,
    serviceOrderId,
    budgetId
  )
  dispatch({
    type: GET_EDIT_EXECUTION_INFO.ACTION,
    payload: data,
  })
  return data
}

export const fetchPaidInstallments = budgetId => (dispatch, getState) => {
  const data = budgetServices.fetchPaidInstallments(getState().user.authToken, budgetId)
  dispatch({
    type: FETCH_PAID_INSTALLMENTS.ACTION,
    payload: data,
  })
  return data
}

export const fetchBudgetServices = params => async (dispatch, getState) => {
  const data = await budgetServices.fetchBudgetServices(getState().user.authToken, params)
  dispatch({
    type: FETCH_BUDGET_SERVICES.ACTION,
    payload: async () => data,
  })
  return data
}

export const postBudgetService = payload => async (dispatch, getState) => {
  const data = await budgetServices.postBudgetService(getState().user.authToken, payload)
  dispatch({
    type: POST_BUDGET_SERVICE.ACTION,
    payload: async () => data,
  })
  return data
}

export const patchBudgetService = (serviceId, payload) => async (dispatch, getState) => {
  const data = await budgetServices.patchBudgetService(
    getState().user.authToken,
    serviceId,
    payload
  )
  dispatch({
    type: PATCH_BUDGET_SERVICE.ACTION,
    payload: async () => data,
  })
  return data
}

export const deleteBudgetService = serviceId => async (dispatch, getState) => {
  const data = await budgetServices.deleteBudgetService(getState().user.authToken, serviceId)
  dispatch({
    type: DELETE_BUDGET_SERVICE.ACTION,
    payload: async () => data,
  })
  return data
}

export const fetchBudgetImportSuggestions = params => async (dispatch, getState) => {
  const data = await budgetServices.fetchBudgetImportSuggestions(getState().user.authToken, params)
  dispatch({
    type: BUDGET_IMPORT_SUGGESTIONS.ACTION,
    payload: async () => data,
  })
  return data
}

export const fetchBudgetAdjustServices = (params, payload) => async (dispatch, getState) => {
  const data = await budgetServices.fetchBudgetAdjustServices(
    getState().user.authToken,
    params,
    payload
  )
  dispatch({
    type: BUDGET_IMPORT_SUGGESTIONS.ACTION,
    payload: async () => data,
  })
  return data
}

export const fetchBudgetUndoImportSuggestions = params => async (dispatch, getState) => {
  const data = await budgetServices.fetchBudgetUndoImportSuggestions(
    getState().user.authToken,
    params
  )
  dispatch({
    type: BUDGET_UNDO_IMPORT_SUGGESTIONS.ACTION,
    payload: async () => data,
  })
  return data
}

export const getReasonsToRefusal = () => (dispatch, getState) =>
  dispatch({
    type: GET_REASONS_TO_REFUSAL.ACTION,
    payload: budgetServices.getReasonsToRefusal(getState().user.authToken),
  })

export const createBudgetTemplate = (budgetId, payload) => async (dispatch, getState) => {
  const data = await budgetServices.createBudgetTemplate(getState().user.authToken)(
    budgetId,
    payload
  )
  dispatch({
    type: CREATE_BUDGET_TEMPLATE.ACTION,
    payload: async () => data,
    meta: payload,
  })
}
export const postBudgetSaveTemplates = (budgetId, payload) => (dispatch, getState) => {
  const data = budgetServices.postBudgetSaveTemplates(getState().user.authToken, budgetId, payload)

  dispatch({
    type: POST_BUDGET_SAVE_TEMPLATES.ACTION,
    payload: data,
  })

  return data
}

export const patchBudgetSaveTemplates = (budgetId, payload, params) => (dispatch, getState) => {
  const data = budgetServices.patchBudgetSaveTemplates(
    getState().user.authToken,
    budgetId,
    payload,
    params
  )

  dispatch({
    type: PATCH_BUDGET_SAVE_TEMPLATES.ACTION,
    payload: data,
  })

  return data
}

export const deleteBudgetSaveTemplates = (budgetId, params) => (dispatch, getState) => {
  const data = budgetServices.deleteBudgetSaveTemplates(getState().user.authToken, budgetId, params)
  dispatch({
    type: DELETE_BUDGET_SAVE_TEMPLATES.ACTION,
    payload: data,
  })
}

export const getBudgetSaveTemplates = budgetId => (dispatch, getState) => {
  const data = budgetServices.postBudgetSaveTemplates(getState().user.authToken, budgetId)

  dispatch({
    type: GET_BUDGET_LOAD_TEMPLATES.ACTION,
    payload: data,
  })

  return data
}

export const postBudgetLoadTemplates = (budgetId, payload) => (dispatch, getState) => {
  const data = budgetServices.postBudgetLoadTemplates(getState().user.authToken, budgetId, payload)

  dispatch({
    type: POST_BUDGET_LOAD_TEMPLATES.ACTION,
    payload: data,
  })

  return data
}

export const getBudgetLoadTemplates = () => (dispatch, getState) => {
  const data = budgetServices.getBudgetLoadTemplates(getState().user.authToken)

  dispatch({
    type: GET_BUDGET_LOAD_TEMPLATES.ACTION,
    payload: data,
  })

  return data
}

export const getVerifyBudgetFinancialValues = budgetId => async (dispatch, getState) => {
  const data = await budgetServices.getVerifyBudgetFinancialValues(
    getState().user.authToken,
    budgetId
  )

  dispatch({
    type: VERIFY_BUDGET_FINANCIAL_VALUES.ACTION,
    payload: async () => data,
  })

  return data
}

export const getBudgetStatus = budgetId => (dispatch, getState) => {
  const data = budgetServices.getBudgetStatus(getState().user.authToken, budgetId)

  dispatch({
    type: GET_BUDGET_STATUS.ACTION,
    payload: data,
  })

  return data
}

export const createCoverageRule = payload => (dispatch, getState) => {
  const data = budgetServices.createCoverageRule(getState().user.authToken, payload)

  dispatch({
    type: CREATE_COVERAGE_RULE.ACTION,
    payload: data,
  })
  return data
}

export const getCoverageRuleVersion =
  (params = {}) =>
  (dispatch, getState) => {
    const data = budgetServices.getCoverageRuleVersion(getState().user.authToken, params)

    dispatch({
      type: GET_COVERAGE_RULE_VERSION.ACTION,
      payload: data,
    })
    return data
  }

export const updateBudgetCoverage = (budgetId, payload) => (dispatch, getState) => {
  const data = budgetServices.updateBudgetCoverage(getState().user.authToken, budgetId, payload)

  dispatch({
    type: UPDATE_BUDGET_COVERAGE.ACTION,
    payload: data,
  })
  return data
}
